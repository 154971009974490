import React, { useState } from "react";
import { CircleTick } from "../assets/icons/svgs";

export const Collapsible: React.FC<any> = ({
  open,
  setOpen,
  title,
  content,
  selectedAtt,
  contentClick,
}) => {
  return (
    <div
      onClick={() => setOpen(!open)}
      className="bg-white  shadow-slate-300 rounded-sm p-5 shadow border-gray-200 mb-4"
    >
      <div className="bg-white  shadow-slate-300 rounded-sm cursor-pointer">
        <p className="font-medium text-sm">{title}</p>
      </div>
      {open && (
        <div className="flex flex-wrap gap-5 pt-5 ">
          {content.map((item: any, index: number) => {
            let i = item.name ?? item.size;
            const selected = selectedAtt === i;

            return (
              <div
                key={index}
                onClick={() => contentClick(item)}
                className={` rounded-sm p-1 px-3 hover:bg-blue-400 cursor-pointer  ${
                  selected ? " bg-green-400" : "bg-slate-300"
                }`}
              >
                <div>
                  <p className="font-medium text-sm">
                    {item.name ?? item.size}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const ConditionsCollapsible: React.FC<any> = ({
  open,
  setOpen,
  title,
  content,
  selectedAtt,
  contentClick,
}) => {
  const [conditionIndex, setConditionIndex] = useState(-1);

  return (
    <div className="bg-white  shadow-slate-300 rounded-sm p-5 shadow border-gray-200 mb-4">
      <div
        onClick={() => setOpen(!open)}
        className="bg-white  shadow-slate-300 rounded-sm cursor-pointer"
      >
        <p className="font-bold text-sm">{title}</p>
      </div>
      {open && (
        <div className=" gap-5 pt-5 ">
          {content.map((item: any, index: number) => {
            const selected = selectedAtt === item.title;
            return (
              <div
                key={index}
                onClick={() => {}}
                className={`bg-slate-100 rounded-sm p-3 px-5  cursor-pointer my-2 `}
              >
                <div onClick={() => setConditionIndex(index)}>
                  <p className="font-bold text-sm">{item.title}</p>

                  {conditionIndex === index && (
                    <div className="flex flex-col border-t mt-4 border-t-slate-300 py-5 ">
                      <p className="font-semibold text-sm">{item.subName}</p>
                      {
                        <ul className=" space-y-1 text-gray-500 list-inside  ">
                          {/* {item.description.map((condition: any) => {
                            return (
                              <li className="flex items-start py-1 pr-28 font-semibold text-sm text-slate-800 mt-6 ">
                                <svg
                                  className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                {condition}
                              </li>
                            );
                          })} */}
                          {item.description
                            .split("\r\n\t•\t") // Split description into list items
                            .filter((line: string) => line.trim() !== "") // Remove empty lines
                            .map((line: any) => (
                              <li
                                key={index}
                                className="flex items-start py-1 pr-28 font-semibold text-sm text-slate-800  "
                              >
                                <CircleTick />
                                {line}
                              </li>
                            ))}
                        </ul>
                      }
                      <button
                        onClick={() => contentClick(item)}
                        className={`" font-base text-sm w-3/6 md:w-1/6 rounded-sm py-2 px-3 mt-5 text-white ${
                          selected ? "bg-green-400" : ""
                        } bg-slate-400 hover:bg-blue-400`}
                      >
                        Confirm
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
