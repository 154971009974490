// src/App.js
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Attributes from "./components/Attributes";
import BackButton from "./components/BackButton";
import { Card, ProductCard } from "./components/Card";
import Email from "./components/Email";
import Hero from "./components/Hero";
import ListButtons from "./components/ListButtons";
import MailIn from "./components/MailIn";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import PaymentGateways from "./components/PaymentGateways";
import ProgressBar from "./components/ProgressBar";
import SearchBar from "./components/SearchBar";
import SelectedItems from "./components/SelectedItems";
import {
  Products,
  Categories,
  orderCreate,
  SubCategories,
  createEshipOrder,
  purchaseOrderToZoho,
  createEshipOrderShipment,
} from "./services/apiServices";
import { validationSchema } from "./services/validations";
import { filterVariants } from "./utils/utils";

const App = () => {
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedAtt, setSelectedAtt] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProductsList, setSelectedProductsList] = useState<any>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [email, setEmail] = useState("");
  const [screen, setScreen] = useState("categories");
  const [shipment, setShipment] = useState<any>();
  const [searchCate, setSearchCate] = useState<string>("");
  const [searchSubCate, setSearchSubCate] = useState<string>("");
  const [searchProd, setSearchProd] = useState<string>("");
  const [connectivity, setConnectivity] = useState(null);
  const [capacity, setCapacity] = useState(null);
  const [conditions, setConditions] = useState(null);
  const [sizes, setSizes] = useState(null);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await Categories();
      setLoading(false);
      setCategories(response);
    } catch (err) {
      setLoading(false);
      console.log("Failed to fetch categories");
    }
  };

  const fetchSubCategories = async (id: any) => {
    setLoading(true);
    setScreen("sub-categories");
    try {
      const response = await SubCategories(id);
      setLoading(false);
      setSubCategories(response);
    } catch (err) {
      setLoading(false);
      console.log("Failed to fetch categories");
    }
  };

  const fetchProducts = async (id: any) => {
    setLoading(true);
    setScreen("products");
    try {
      const response = await Products(id);
      setLoading(false);
      setProducts(response);
    } catch (err) {
      setLoading(false);
      console.log("Failed to fetch categories");
    }
  };

  const selectProduct = (item: any) => {
    setScreen("attributes");
    setSelectedProduct(item);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");
  const year = today.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  const [selectedAttributes, setSelectedAttributes] = useState<any>();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmitAtt = (att: any, selectedAttributes: any) => {
    const filteredAttributes = filterVariants(
      selectedProduct?.variants,
      selectedAttributes
    );

    if (email) {
      setScreen("list");
      const updatedProduct = {
        ...selectedProduct,
        attributes: att,
        connectivity: connectivity ?? null,
        conditions: conditions ?? null,
        case_size: sizes ?? null,
        capacity: capacity ?? null,
        variants: filteredAttributes,
        quantity: 1,
      };

      console.log("updatedProduct...", updatedProduct);

      setSelectedProductsList([...selectedProductsList, updatedProduct]);
    } else {
      setScreen("email");
    }

    setSelectedAtt(att);
  };

  const emailSave = () => {
    setScreen("mail-in");
  };

  const handleMailIn = () => {
    setScreen("list");

    const filteredAttributes = filterVariants(
      selectedProduct?.variants,
      selectedAttributes
    );

    const updatedProduct = {
      ...selectedProduct,
      attributes: selectedAtt,
      connectivity: connectivity ?? null,
      conditions: conditions ?? null,
      case_size: sizes ?? null,
      capacity: capacity ?? null,
      variants: filteredAttributes,
      quantity: 1,
    };

    if (updatedProduct?.variants) {
      setSelectedProductsList([...selectedProductsList, updatedProduct]);
    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again",
      });
    }
  };

  const removeSelectedItem = (id: any) => {
    setSelectedProductsList(
      selectedProductsList.filter((item: any) => item.id !== id)
    );
  };

  const filteredCategories = categories.filter((category: { name: string }) =>
    category.name.toLowerCase().includes(searchCate.toLowerCase())
  );

  const filteredSubCategories = subCategories.filter(
    (category: { name: string }) =>
      category.name.toLowerCase().includes(searchSubCate.toLowerCase())
  );
  const filteredProducts = products.filter((item: { name: string }) =>
    item.name.toLowerCase().includes(searchProd.toLowerCase())
  );

  const totalPrice =
    selectedProductsList.length > 0
      ? selectedProductsList.reduce(
          (total: number, product: { variants: any; quantity: number }) => {
            return total + product.variants.price * product.quantity;
          },
          0
        )
      : 0;

  // const data = {
  //   billing_address: {
  //     first_name: "Sadiq",
  //     last_name: "Rauf",
  //     email: "alice.smith@example.com",
  //     phone: "987654321",
  //     mobile: "+1-987-654-3210",
  //     address: "37 Normanby Road",
  //     address2: "Mount Eden",
  //     city: "Auckland",
  //     state: "Auckland",
  //     zip_code: "1024",
  //     country: "New Zealand",
  //   },
  //   order_date: "2024-12-06",
  //   total_price: 1245.0,
  //   payment_method: 2,

  //   items: result,
  // };
  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    setBtnLoading(true);
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setStreetAddress(values.streetAddress);

    const result = selectedProductsList.map((item: any) => ({
      quantity: item.quantity,
      product_variant: item.variants.id,
      variant_price: item.variants.price,
    }));
    const currentDate = new Date().toISOString().split("T")[0];
    const data = {
      billing_address: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        mobile: values.mobile,
        address: values.streetAddress,
        address2: values.streetAddress2,
        city: values.city,
        state: values.region,
        zip_code: values.postalCode,
        country: values.country,
      },
      order_date: currentDate,
      total_price: totalPrice.toFixed(2),
      payment_method: paymentMethod,

      items: result,
    };

    try {
      const response = await orderCreate(data);

      PurchaseOrderToZoho(response.id, response.id);
      // resetForm();
    } catch (error) {
      setBtnLoading(false);
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const PurchaseOrderToZoho = async (
    order_id: number,
    billing_address_id: any
  ) => {
    try {
      const response = await purchaseOrderToZoho(order_id, billing_address_id);
      console.log(response, "/////");

      CreateEshipOrder(order_id);
    } catch (error) {
      setBtnLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again",
      });
    }
  };

  const CreateEshipOrder = async (order_id: number) => {
    try {
      const response = await createEshipOrder(order_id);
      console.log(response, "======");
      CreateEshipOrderShipment(order_id);
    } catch (error) {
      setBtnLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again",
      });
    }
  };

  const CreateEshipOrderShipment = async (order_id: number) => {
    try {
      const response = await createEshipOrderShipment(order_id);
      console.log(response, "----");
      setShipment(response);
      setScreen("get-paid");
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again",
      });
    }
  };

  useEffect(() => {
    if (screen === "list" && selectedProductsList.length < 1) {
      setScreen("categories");
    }
  }, [selectedProductsList, screen]);

  const increaseQuantity = (index: number) => {
    const updatedCart = [...selectedProductsList];
    updatedCart[index].quantity += 1;
    setSelectedProductsList(updatedCart);
  };

  const decreaseQuantity = (index: number) => {
    const updatedCart = [...selectedProductsList];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
      setSelectedProductsList(updatedCart);
    }
  };

  // const handleGeneratePDF = () => {
  //   // Example of encoded data (Base64 string)
  //   const encodedData = shipment.pdf_data;

  //   // Decode Base64
  //   const decodedData = atob(encodedData);

  //   // Create a new jsPDF instance
  //   const pdf = new jsPDF();

  //   // Add the decoded content to the PDF
  //   pdf.text(decodedData, 10, 10); // (text, x, y)

  //   // Save the PDF
  //   pdf.save("sample.pdf");
  // };

  const handleGeneratePDF = () => {
    try {
      const binaryData = atob(shipment.pdf_data);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "document.pdf"; // The desired filename
      link.click(); // Trigger the download

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Failed to download the PDF:", error);
    }
  };
  
  return (
    <>
      <NavBar />
      <div className="flex justify-center items-center">
        <div className="w-11/12 md:w-9/12 py-10">
          <Hero />
          <div
            className={`${
              screen === "sub-categories" ||
              screen === "categories" ||
              screen === "products"
                ? "my-4"
                : "my-0"
            } flex items-center justify-center py-10 p-3 md:p-10 w-full `}
          >
            <ProgressBar screen={screen} />
          </div>

          {/* /////////////////////////////////// SEARCH BAR /////////////////////////////////// */}
          <>
            {screen === "categories" && (
              <div className="mb-3 flex items-center justify-center pb-10 w-full ">
                <SearchBar
                  value={searchCate}
                  onchange={(e: any) => setSearchCate(e.target.value)}
                />
              </div>
            )}
            {screen === "sub-categories" && (
              <div className="mb-3 flex items-center justify-center pb-10 w-full ">
                <SearchBar
                  value={searchSubCate}
                  onchange={(e: any) => setSearchSubCate(e.target.value)}
                />
              </div>
            )}
            {screen === "products" && (
              <div className="mb-3 flex items-center justify-center pb-10 w-full ">
                <SearchBar
                  value={searchProd}
                  onchange={(e: any) => setSearchProd(e.target.value)}
                />
              </div>
            )}
          </>

          {/* /////////////////////////////////// BACK /////////////////////////////////// */}
          {screen === "sub-categories" && (
            <BackButton onClick={() => setScreen("categories")} />
          )}
          {screen === "products" && (
            <BackButton onClick={() => setScreen("sub-categories")} />
          )}

          {screen === "attributes" && (
            <BackButton onClick={() => setScreen("products")} />
          )}

          {screen === "get-paid" && (
            <BackButton
              onClick={() => {
                window.location.reload();
                setScreen("categories");
              }}
            />
          )}

          {/* /////////////////////////////////// SELECTED PRODUCTS LIST /////////////////////////////////// */}
          {selectedProductsList.length > 0 && screen !== "get-paid" && (
            <SelectedItems
              items={selectedProductsList}
              setScreen={setScreen}
              screen={screen}
              totalPrice={totalPrice}
              removeSelectedItem={removeSelectedItem}
              decreaseQuantity={(index: number) => decreaseQuantity(index)}
              increaseQuantity={(index: number) => increaseQuantity(index)}
            />
          )}

          {/* /////////////////////////////////// SCREENS /////////////////////////////////// */}
          {screen === "categories" ? (
            loading ? (
              <div className="flex items-center text-center w-full">
                <div className="loader"></div>
              </div>
            ) : filteredCategories.length < 1 ? (
              <NotFound />
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-10">
                {filteredCategories?.map((item: any, index: number) => (
                  <div key={index} onClick={() => fetchSubCategories(item.id)}>
                    <Card item={item} />
                  </div>
                ))}
              </div>
            )
          ) : screen === "sub-categories" ? (
            loading ? (
              <div className="flex items-center text-center w-full">
                <div className="loader"></div>
              </div>
            ) : filteredSubCategories.length < 1 ? (
              <NotFound />
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-10">
                {filteredSubCategories?.map((item: any, index: number) => (
                  <div key={index} onClick={() => fetchProducts(item.id)}>
                    <Card item={item} />
                  </div>
                ))}
              </div>
            )
          ) : screen === "products" ? (
            loading ? (
              <div className="flex items-center text-center w-full">
                <div className="loader"></div>
              </div>
            ) : filteredProducts.length < 1 ? (
              <NotFound />
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5 md:gap-10">
                {filteredProducts?.map((item: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      selectProduct(item);
                    }}
                  >
                    <ProductCard item={item} isProduct={true} />
                  </div>
                ))}
              </div>
            )
          ) : screen === "attributes" ? (
            <div className="w-full p-3 sm:p-8 md:p-14 bg-slate-100 rounded-lg">
              <Attributes
                content={selectedProduct}
                setSelectedAttributes={setSelectedAttributes}
                handleSubmitAtt={handleSubmitAtt}
                setConnectivity={setConnectivity}
                setConditions={setConditions}
                setCapacity={setCapacity}
                setSizes={setSizes}
              />
            </div>
          ) : screen === "email" ? (
            <div className="w-full p-3 sm:p-8 md:p-14 bg-slate-100 rounded-lg">
              <div className="px-3">
                <Email
                  email={email}
                  setEmail={setEmail}
                  emailSave={emailSave}
                />
              </div>
            </div>
          ) : screen === "mail-in" ? (
            <MailIn onClick={handleMailIn} />
          ) : screen === "list" ? (
            <ListButtons setScreen={setScreen} />
          ) : screen === "checkout" ? (
            <div>
              <Formik
                initialValues={{
                  email: email,
                  firstName: "",
                  lastName: "",
                  country: "New Zealand",
                  phone: "",
                  mobile: "",
                  streetAddress: "",
                  streetAddress2: "",
                  city: "",
                  region: "",
                  postalCode: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="border-b border-gray-900/10 bg-slate-100 rounded-md p-3 sm:p-8 md:p-14 my-5">
                    {/* Personal Information */}
                    <div>
                      <h2 className="text-lg font-semibold text-gray-900">
                        Personal Information
                      </h2>
                      <p className="mt-1 text-sm text-gray-600">
                        Use a permanent address where you can receive mail.
                      </p>
                    </div>

                    {/* Email Field */}
                    <div className="sm:col-span-2 mt-6">
                      <label className="block text-sm/6 font-medium text-gray-900">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="pb-6">
                      <label className="block mb-4 text-lg font-semibold text-gray-900 mt-12">
                        Payment Methods
                      </label>
                      <PaymentGateways
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                      />
                    </div>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          First name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="first name"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Last name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="last name"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="lastName"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      {/* Country */}

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Phone
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="phone"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>
                        <ErrorMessage
                          name="phone"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Mobile
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            placeholder="+1-987-654-3210"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>
                        <ErrorMessage
                          name="mobile"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Country
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            id="country"
                            name="country"
                            placeholder="country name"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>
                        <ErrorMessage
                          name="country"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          City
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            placeholder="city"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="city"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Street address
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="streetAddress"
                            id="streetAddress"
                            placeholder="Address"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="streetAddress"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          Street address 2
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="streetAddress2"
                            id="streetAddress2"
                            placeholder="Address 2"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="streetAddress2"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          State / Province
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="region"
                            id="region"
                            placeholder="region"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="region"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-sm/6 font-medium text-gray-900">
                          ZIP / Postal code
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="postal code"
                            className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                          />
                        </div>

                        <ErrorMessage
                          name="postalCode"
                          component="p"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={btnLoading}
                        className="font-base cursor-pointer flex items-center justify-center text-sm font-medium rounded-lg py-3 px-4 mt-3 text-white bg-blue-400 hover:bg-green-400"
                      >
                        {btnLoading && (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 mr-3 text-gray-200 animate-spin  fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <p className="text-sm"> {"Submit"}</p>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            // <GetPaid
            //   formattedDate={formattedDate}
            //   paymentMethod={paymentMethod}
            //   streetAddress={streetAddress}
            //   firstName={firstName}
            //   lastName={lastName}
            //   selectedProductsList={selectedProductsList}
            //   totalPrice={totalPrice}
            // />
            <div className=" bg-slate-100 p-14 rounded-lg">
              <h1 className=" font-bold text-3xl text-black">
                Ready to get paid?
              </h1>
              <p className=" font-semibold text-sm mt-4 text-black">
                We have locked in your order for 30 days. Before you ship out
                your device make sure to backup your device, remove your
                iCloud/Google/Samsung account, and Erase all data from your
                device before shipping your device.
              </p>

              <div className="my-10">
                <h1 className=" font-semibold text-xl text-black">
                  Please find your offer details below:
                </h1>

                <div className="grid grid-cols-2">
                  <div className="mt-4">
                    <h1 className=" text-slate-400 font-semibold text-sm">
                      Offer#
                    </h1>
                    <p className=" text-black font-normal text-sm">TE12899</p>

                    <h1 className=" text-slate-400 font-semibold text-sm mt-5">
                      Date:
                    </h1>
                    <p className=" text-black font-normal text-sm">
                      {formattedDate}
                    </p>

                    <h1 className=" text-slate-400 font-semibold text-sm mt-5">
                      Payout Method:
                    </h1>
                    <p className=" text-black font-normal text-sm">
                      {paymentMethod === 1 ? "Donate" : "Paypal"}
                    </p>
                  </div>

                  <div className=" mt-5">
                    <h1 className=" text-slate-400 font-semibold text-sm">
                      Your Address:
                    </h1>
                    <p>{streetAddress}</p>

                    <h1 className=" text-slate-400 font-semibold text-sm mt-5">
                      Tracking Number:
                    </h1>
                    <p className="mb-6">{shipment?.tracking_number}</p>

                    <button
                      className=" bg-blue-400 hover:bg-blue-500 select-none text-base text-white p-2 px-6 rounded-md font-semibold py-3.5 cursor-pointer"
                      onClick={handleGeneratePDF}
                    >
                      {" "}
                      Shipping Label
                    </button>
                    {/* <PDFDownloadLink
                      className=" bg-blue-400 hover:bg-blue-500 select-none text-base text-white p-2 px-6 rounded-md font-semibold py-3.5 cursor-pointer"
                      document={
                        <ShippingLabel
                          address={streetAddress}
                          firstName={firstName}
                          lastName={lastName}
                          date={formattedDate}
                          paymentMethod={paymentMethod}
                          selectedProductsList={selectedProductsList}
                        />
                      }
                      fileName="cell.pdf"
                    >
                      Shipping Label
                    </PDFDownloadLink> */}
                  </div>
                </div>
              </div>

              <div className="mt-10 pt-20">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="108"
                    height="108"
                  >
                    <path
                      fill="#ffc950"
                      d="M85 57.327H64v-36.41L43 70.673h21v36.41l21-49.756z"
                    />
                  </svg>
                  <h1 className="font-semibold text-2xl text-black mt-8">
                    Looking to speed up your payment? Add your IMEl/SN below,
                    and we'll process your offer quick
                  </h1>
                </div>
                <div className="mt-16">
                  <h1 className=" font-semibold text-xl text-black">
                    What I'm Selling:
                  </h1>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-slate-300  ">
                      <tr>
                        <th scope="col" className="px-6 py-5">
                          Product name
                        </th>
                        <th scope="col" className="px-6 py-5">
                          IMEI/SN(Optional)
                        </th>
                        <th scope="col" className="px-6 py-5">
                          Qty
                        </th>
                        <th scope="col" className="px-6 py-5">
                          Payout
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProductsList.map((item: any, index: number) => {
                        return (
                          <tr
                            key={index}
                            className="odd:bg-white  even:bg-gray-100  border-b "
                          >
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap "
                            >
                              {item.name}
                            </th>
                            <td className="px-6 py-3">
                              <input
                                type="text"
                                name="imei"
                                id="IMEI"
                                placeholder="enter here..."
                                className="bg-gray-50 border py-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/5 p-2.5 dark:border-gray-400 dark:focus:ring-blue-400"
                                // value={postalCode}
                                // onChange={(e) => setPostalCode(e.target.value)}
                              />
                            </td>
                            <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">
                              {item.quantity}
                            </td>
                            <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">
                              ${item.price}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="odd:bg-white  even:bg-gray-100  border-b ">
                        <th
                          scope="row"
                          className="px-6 py-3 font-semibold text-gray-900 whitespace-nowrap "
                        >
                          {"Subtotal"}
                        </th>
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"></td>
                        <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">
                          ${totalPrice.toFixed(2)}
                        </td>
                      </tr>

                      <tr className="odd:bg-white  even:bg-gray-100  border-b ">
                        <th
                          scope="row"
                          className="px-6 py-3 font-bold text-xl text-gray-900 whitespace-nowrap "
                        >
                          {"Offer"}
                        </th>
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"></td>
                        <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">
                          ${totalPrice.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
