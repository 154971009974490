import { on } from "events";
import React, { FC } from "react";
import { BackArrow } from "../assets/icons/svgs";

const BackButton: FC<any> = ({ onClick }) => {
  return (
    <div id="back-to-categories-button" className=" mb-10">
      <button
        onClick={onClick}
        className="backButton bg-primary"
        id="backToCategories"
      >
        <span className="IconContainer">
          <BackArrow />
        </span>
        <p className="text">Back</p>
      </button>
      {/* <p className=" text-sky-500 font-semibold text-2xl  ml-3">{{ category.name }}</p> */}
    </div>
  );
};

export default BackButton;
