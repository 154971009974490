import { FC } from "react";
import { useFormik } from "formik";
import { emailValidationSchema } from "../services/validations";
import { Mail } from "../assets/icons/svgs";

interface EmailProps {
  email: string;
  setEmail: (email: string) => void;
  emailSave: () => void;
}

const Email: FC<EmailProps> = ({ email, setEmail, emailSave }) => {
  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      setEmail(values.email);
      emailSave()
    },
  });
  
  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Your Email
      </label>
      <div className="relative mb-6">
        <div className="absolute inset-y-0 start-0 mt-5 ps-3.5 pointer-events-none">
        <Mail/>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            name="email"
            id="input-group-1"
            className={`bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 ${
              formik.errors.email && formik.touched.email
                ? "border-red-500 focus:ring-red-500"
                : ""
            }`}
            placeholder="user@example.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p>
          )}
          <button
            type="submit"
            className="rounded-lg font-base text-sm font-medium md:w-1/6  py-3 px-4 md:mt-3 text-white bg-blue-400 hover:bg-green-400"
          >
            Save Email
          </button>
        </form>
      </div>
    </>
  );
};

export default Email;
