import { FC } from "react"
import { Email } from "../assets/icons/svgs"


const MailIn:FC<any> = ({onClick}) => {
  return (
    <div onClick={onClick} className="flex items-center justify-center ">
    <div className="bg-white shadow border-2 cursor-pointer border-blue-400 hover:border-blue-500 p-12 md:w-3/6 rounded-md flex items-center">
      <Email/>
      <p className=" font-semibold text-5xl text-blue-400 mt-3 ml-2">
        Mail In
      </p>
    </div>
  </div>
  )
}

export default MailIn