import * as Yup from "yup";
export const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  country: Yup.string().required("Country is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Phone must be a number")
    .min(9, "Phone must be at least 10 digits")
    .required("Phone is required"),
  mobile: Yup.string()
    .matches(
      /^\+\d{1}-\d{3}-\d{3}-\d{4}$/,
      "Mobile number must be in the format +1-987-654-3210"
    )
    .required("Mobile number is required"),

  streetAddress: Yup.string().required("Street address is required"),
  streetAddress2: Yup.string().required("Street address is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("State/Province is required"),
  postalCode: Yup.string()
    .matches(/^[0-9]+$/, "Postal code must be a number")
    .required("Postal code is required"),
});

export const emailValidationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format. Example: user@example.com"
    )
    .required("Email is required"),
});
