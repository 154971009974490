import React, { useState } from "react";
import { Collapsible, ConditionsCollapsible } from "./Collapsible";


const Attributes = ({
  content,
  handleSubmitAtt,
  setConnectivity,
  setCapacity,
  setConditions,
  setSizes,
  setSelectedAttributes
}: any) => {
  const [openConnectivity, setOpenConnectivity] = useState(true);
  const [openCapacity, setOpenCapacity] = useState(false);
  const [openConditions, setOpenConditions] = useState(false);
  const [openSizes, setOpenSizes] = useState(false);

  const [connectivity1, setConnectivity1] = useState("");
  const [capacity1, setCapacity1] = useState("");
  const [conditions1, setConditions1] = useState("");
  const [sizes1, setSizes1] = useState("");

  const [isDisable, setIsDisable] = useState(true);

  const handleSubmit = () => {
    const att = [];

    if (connectivity1) att.push(connectivity1);
    if (capacity1) att.push(capacity1);
    if (conditions1) att.push(conditions1);
    if (sizes1) att.push(sizes1);


    const selectedAtt = { connectivity: connectivity1, capacity: capacity1, condition: conditions1, case_size: sizes1 };
    setSelectedAttributes(selectedAtt)
    const attString = att.join(", ");
    handleSubmitAtt(attString, selectedAtt);
    console.log(attString);
  };

  return (
    <div className="  ">
      {content.connectivity.length > 0 && (
        <Collapsible
          open={openConnectivity}
          setOpen={setOpenConnectivity}
          title="Connectivity"
          selectedAtt={connectivity1}
          content={content.connectivity}
          contentClick={(item: any) => {
            setConnectivity(item.id);
            setConnectivity1(item.name);
            setOpenCapacity(true);
            setOpenSizes(true);
          }}
        />
      )}

      {content.case_size.length > 0 && (
        <Collapsible
          open={openSizes}
          setOpen={setOpenSizes}
          title="Case Size"
          selectedAtt={sizes1}
          content={content.case_size}
          contentClick={(item: any) => {
            setSizes(item.id);
            setSizes1(item.size);
            setOpenCapacity(true);
            setOpenConditions(true);
          }}
        />
      )}

      {content.capacity.length > 0 && (
        <Collapsible
          open={openCapacity}
          setOpen={setOpenCapacity}
          title="Capacity"
          selectedAtt={capacity1}
          content={content.capacity}
          contentClick={(item: any) => {
            setCapacity(item.id);
            setCapacity1(item.size);
            setOpenConditions(true);
          }}
        />
      )}

      {content.conditions.length > 0 && (
        <ConditionsCollapsible
          open={openConditions}
          setOpen={setOpenConditions}
          title="Conditions"
          selectedAtt={conditions1}
          content={content?.conditions}
          contentClick={(item: any) => {
            setConditions(item.id);
            setConditions1(item.title);
            setOpenConditions(false);
            setIsDisable(false);
          }}
        />
      )}

      <button
        onClick={handleSubmit}
        disabled={isDisable}
        className={` font-base text-sm font-medium md:w-1/6 rounded-lg py-3 px-4 mt-3 text-white   ${
          isDisable
            ? " bg-slate-400"
            : "hover:bg-green-400 bg-blue-400 rounded-lg"
        }`}
      >
        Confirm
      </button>
    </div>
  );
};

export default Attributes;
