import { FC, useState } from "react";
import { ChevronDown, ChevronUp, Send } from "../assets/icons/svgs";
import { MEDIA_URL } from "../constant/constant";

const SelectedItems: FC<any> = ({
  items,
  screen,
  setScreen,
  totalPrice,
  increaseQuantity,
  decreaseQuantity,
  removeSelectedItem,
}) => {
  const [openContainer, setOpenContainer] = useState<boolean>(true);

  return (
    <div className=" p-3 sm:p-6 md:p-14  bg-slate-100 rounded-md mb-10">
      <div className=" flex items-center justify-end px-0.5">
        <div className="flex mr-4">
          <p className=" text-right text-base font-bold">{"Total Payout:  "}</p>
          <p className=" text-base font-bold text-gray-500 ml-3">
            $ {totalPrice ?? "00.00"}
          </p>
        </div>

        {["categories", "sub-categories", "products"].includes(screen) && (
          <button
            onClick={() => setScreen("list")}
            className="payButton shadow"
          >
            <span className="buttonDecor"></span>
            <div className="buttonContent">
              <div className="buttonIcon">
                <Send />
              </div>
              <span className="buttonText">Get Paid</span>
            </div>
          </button>
        )}

        <button
          onClick={() => setOpenContainer(!openContainer)}
          className="p-3"
        >
          {openContainer ? (
          <ChevronUp/>
          ) : (
           <ChevronDown/>
          )}
        </button>
      </div>

      {openContainer && (
        <div
          className={`itemContainer ${
            openContainer ? "slide-down " : "slide-up "
          }`}
        >
          {items.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className=" my-5 rounded-lg p-3 px-2 justify-between flex items-center bg-white shadow"
              >
                <div className="flex items-center">
                  <div className="h-20 w-20 md:h-[70px] md:w-[70px] mr-3 rounded-sm bg-white flex items-center">
                    <img
                      src={`${MEDIA_URL}${item.image}`}
                      alt="Smartphone"
                      className=" object-cover h-auto"
                    />
                  </div>
                  <div>
                    <p className="text-xs sm:text-sm md:text-base text-left font-bold text-black">
                      {item.name}
                    </p>
                    <p className=" text-xs text-left font-light text-gray-500 my-1">
                      Price $ {item.variants.price}
                    </p>
                    <p className=" text-xs text-left font-medium text-black">
                      {item.attributes}
                    </p>
                  </div>
                </div>

                <div className="flex  relative">
                  <div className="flex  justify-center items-center">
                    <button
                      disabled={screen === "complete"}
                      onClick={() => decreaseQuantity(index)}
                      className=" bg-white shadow hover:shadow-md hover:shadow-slate-400 flex justify-center items-center h-6 w-6 sm:w-8 sm:h-8 rounded-md text-black"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="15px"
                        height="9.737px"
                        viewBox="0 0 122.881 9.737"
                        enableBackground="new 0 0 122.881 9.737"
                      >
                        <g>
                          <path d="M117.922,0.006C117.951,0.002,117.982,0,118.012,0c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017 c0.584,0.248,1.107,0.603,1.543,1.039c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859 c-0.248,0.6-0.609,1.137-1.057,1.583c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37 c-0.029,0-0.061-0.002-0.09-0.006c-37.654,0-75.309,0.001-112.964,0.001c-0.029,0.004-0.059,0.006-0.09,0.006 c-0.654,0-1.283-0.132-1.859-0.371c-0.6-0.248-1.137-0.609-1.583-1.056C0.981,7.865,0.621,7.33,0.372,6.73H0.371 C0.132,6.154,0,5.525,0,4.869C0,4.215,0.132,3.586,0.371,3.01c0.249-0.6,0.61-1.137,1.056-1.583 c0.881-0.881,2.098-1.426,3.442-1.426c0.031,0,0.061,0.002,0.09,0.006C42.613,0.006,80.268,0.006,117.922,0.006L117.922,0.006z" />
                        </g>
                      </svg>
                    </button>
                    <span className="p-1  sm:p-4 text-black">
                      {item.quantity}
                    </span>
                    <button
                      disabled={screen === "complete"}
                      onClick={() => increaseQuantity(index)}
                      className=" bg-white shadow hover:shadow-md hover:shadow-slate-400  flex justify-center items-center h-6 w-6 sm:w-8 sm:h-8 rounded-md text-black"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="15px"
                        height="15px"
                        viewBox="0 0 122.881 122.88"
                        enableBackground="new 0 0 122.881 122.88"
                      >
                        <g>
                          <path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z" />
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div className="hidden md:block">
                    <div className=" flex md:w-40  justify-end  pr-2">
                      <button
                        disabled={screen === "complete"}
                        onClick={() => {
                          removeSelectedItem(item.id);
                        }}
                        className="deleteButton"
                      >
                        <svg viewBox="0 0 448 512" className="svgIcon">
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="block md:hidden">
                    <div className=" absolute bottom-10 h-5 w-5 rounded-sm bg-red-400 flex justify-center items-center right-0">
                      <button
                        onClick={() => {
                          removeSelectedItem(item.id);
                        }}
                        className=" text-white text-xs"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectedItems;
